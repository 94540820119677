export const entryTypes = {
  magazine: 'Magazine Article',
  blog: 'Blog',
  ebook: 'Ebook',
  quickRead: 'Quick Read',
}

export const contentPackageTypes = {
  basic: 'Basic',
  default: 'Default',
  academy: 'Academy',
}

export const elementTypes = {
  page: 'Page',
  section: 'Section',
  product: 'Products',
  // container: 'Container',
}

export const subscriptionTypes = {
  basic: 'Basic',
  default: 'Default',
  academy: 'Academy'
}

export const magazineTypes = {
  advantage: 'Advantage',
  'in-the-loop': 'In the Loop',
}

export const titleColors = {
  'title-white': 'White',
}

export const maxPeopleGroup = 5;

// This is silly but preventing hardcoding. 
export const pageTypes = {
  resource: 'resource',
}

export const siteStates = {
  wip: 'Work in Progress',
  live: 'Live',
  archive: 'Archive',
  tester: 'Tester / Demo',
}

// Only certain subscriptions gets global blogs.
export const getsGlobalBlogs = [
  'academy',
]

// If this gets touched, update the PHP Enum too in API.
export const pageStatusTypes = {
  draft: 'draft',
  live: 'live',
}

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const integrationOptions = {
  'grecaptcha': 'Google Recaptcha',
  'accessibe': 'AccessiBe',
};

// To view options, you'll need to log in and view it here: https://dashboard.accessibe.com/app/installation
export const accessibeIcons = [
  'display',
  'display2',
  'display3',
  'help',
  'people',
  'people2',
  'settings',
  'settings2',
  'wheels', 
  'wheels2',
];