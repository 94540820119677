import gql from 'graphql-tag'

let SiteDetails = gql`
  fragment SiteDetails on Site {
    id
    title
    account_id
    domain
    key
    parked
    theme
    state
    app
    nested_set
    private_repo
    created_at
    ssl_expiration_date
    updated_at
  }
`

export const SITE_DETAILS_FRAGMENT = SiteDetails

export const SITE = gql`
  query Site($site_id: ID!) {
    site(id: $site_id) {
      ...SiteDetails
      forgeServers {
        id
        site_id
        state
        forge_server_id
        forge_site_id
        running_state
      }
      contentSets {
        id
        title
        type
      }
    }
  }
  ${SiteDetails}
`

export const SITE_LIST_PAGINATE = gql`
  query Sites(
      $first: Int!, 
      $page: Int, 
      $state: String,
      $title: String,
      $key: String,
      $orderBy: [SitesListOrderByOrderByClause!]
    ) {
    sitesList(
      first: $first, 
      page: $page, 
      title: $title,
      key: $key,
      state: $state, 
      orderBy: $orderBy) {
      data {
        id
        title
        key
        state
        app
        nested_set
        created_at
        ssl_expiration_date
        updated_at
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const SITE_LIST = gql`
  query Sites($states: [String]) {
    sites(states: $states) {
      id
      title
      key
      state
      app
      nested_set
      created_at
      updated_at
    }
  }
`

export const DELETED_SITES = gql`
  query DeletedSites {
    sites(trashed: ONLY) {
      id
      title
      created_at
      deleted_at
      key
    }
  }
`

export const RESTORE_SITE = gql`
  mutation RestoreSite($id: ID!) {
    restoreSite(id: $id) {
      id
    }
  }
`

export const DELETE_SITE = gql`
  mutation DeleteSite($id: ID!) {
    deleteSite(id: $id) {
      id
    }
  }
`

import { SITE_SETTINGS_FRAGMENT, CONNECTOR_FRAGMENT, SITE_MEMBERSHIP_FRAGMENT } from './site-settings'
import { OFFICE_DETAILS_FRAGMENT } from './offices'

let SiteSettingsFragment = gql`
  fragment SiteSettings on Site {
    id
    siteSettings {
      ...SettingsDetail
    }
    offices {
      ...OfficeDetails
    }
    connectors {
      ...ConnectorDetails
    }
    memberships {
      ...SiteMembershipDetails
    }
  }
  ${SITE_SETTINGS_FRAGMENT}
  ${OFFICE_DETAILS_FRAGMENT}
  ${CONNECTOR_FRAGMENT}
  ${SITE_MEMBERSHIP_FRAGMENT}
`

export const SITE_SETTINGS = gql`
  query SiteSettings($site_id: ID!) {
    site(id: $site_id) {
      ...SiteSettings
    }
  }
  ${SiteSettingsFragment}
`

let SiteIntegrationsFragment = gql`
  fragment SiteIntegrationDetails on SiteIntegration {
    id
    site_id
    provider
    key
    secret
    accessibe_settings
  }
`

export const SITE_INTEGRATIONS = gql`
  query SiteIntegrations($site_id: ID!) {
    site(id: $site_id) {
      id
      siteIntegrations {
        ...SiteIntegrationDetails
      }
    }
  }
  ${SiteIntegrationsFragment}
`

export const UPDATE_SITE_INTEGRATION = gql`
  mutation UpsertSiteIntegration($input: UpdateSiteIntegrationInput!) {
    upsertSiteIntegrations(input: $input) {
      id
      siteIntegrations {
        ...SiteIntegrationDetails
      }
    }
  }
  ${SiteIntegrationsFragment}
`

let SiteAnnouncementFragment = gql`
  fragment SiteAnnouncementDetails on SiteAnnouncement {
    id
    site_id
    enabled
    text
    type
    includeLink
    link
    enabled_at
    disabled_at
    created_at
    updated_at
  }
`

export const SITE_ANNOUNCEMENT = gql`
  query SiteAnnouncement($site_id: ID!) {
    site(id: $site_id) {
      id
      siteAnnouncement {
        ...SiteAnnouncementDetails
      }
    }
  }
  ${SiteAnnouncementFragment}
`

export const UPDATE_SITE_ANNOUNCEMENT = gql`
  mutation UpdateSiteAnnouncement($input: UpdateSiteAnnouncementInput!) {
    updateSiteAnnouncement(input: $input) {
      id
      siteAnnouncement {
        ...SiteAnnouncementDetails
      }
    }
  }
  ${SiteAnnouncementFragment}
`

export const SITE_ACTIONS = gql`
  query SiteActions($site_id: ID!) {
    siteActions(site_id: $site_id) {
      id
      action
      status
      created_at
      updated_at
    }
  }
`

export const CREATE_SITE = gql`
  mutation CreateSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      id
      key
      created_at
    }
  }
`

export const UPDATE_SITE = gql`
  mutation UpdateSite($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      id
      title
      key
      created_at
      updated_at
    }
  }
`

export const UPDATE_DOMAIN = gql`
  mutation UpdateDomain($input: UpdateDomainInput!) {
    updateDomain(input: $input) {
      id
      domain
      updated_at
    }
  }
`

export const SAVE_SITE_SETTINGS = gql`
  mutation UpdateSiteSettings($input: UpdateSiteSettingsInput!) {
    updateSiteSettings(input: $input) {
      ...SiteSettings
    }
  }
  ${SiteSettingsFragment}
`

export const SITES_BY_ACCOUNT_ID = gql`
  query FetchSitesByAccountId($id: ID, $account_id: ID) {
    sitesByAccountId(id: $id, account_id: $account_id) {
      id
      title
    }
  }
`
